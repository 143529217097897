<template>
  <div :id="elemId" style="height: 100%, width: 100%">
    <b-modal v-model="selectorShow" size="xl" :title="selectorTitleLabel" footer-class="footerClass"
      no-close-on-backdrop 
      :no-close-on-esc="isCellEditing || editorShow" :modal-class="[elemId]"
      content-class="shadow" @hidden="hidden" scrollable>
      
      <AlertFeedback v-if="alertObj.msg != null" 
        :msg="alertObj.msg" 
        :details="alertObj.msgDetails.list" 
        :detailTitle="alertObj.msgDetails.title" 
        :alertState="alertObj.state" 
        @resetAlert="resetAlert"
      />
      
      <div class="grid-toolbar border" v-if="allowManage">
        <span :id="`BTN_EDIT_${elemId}`">
          <b-btn :disabled="disableEdit" @click="openEditor(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
        </span>
        <b-popover :target="`BTN_EDIT_${elemId}`" triggers="hover" placement="top">
          {{ $t('button.edit') }}
        </b-popover>
        <span :id="`BTN_DELETE_${elemId}`">
          <b-btn :disabled="disableDelete" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
        </span>
        <b-popover :target="`BTN_DELETE_${elemId}`" triggers="hover" placement="top">
          {{ $t('button.delete') }}
        </b-popover>
      </div>
      
      <ag-grid-vue style="width: 100%" class="ag-theme-balham generic-selector selector-grid-height spreadsheet" id="rebate-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :autoGroupColumnDef="autoGroupColumnDef"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            :rowMultiSelectWithClick="false"
            :rowSelection="'multiple'"
            :serverSideInfiniteScroll="true"
            :sideBar="false"
            suppressContextMenu
            suppressDragLeaveHidesColumns
            :suppressCellFocus="false"
            :singleClickEdit="false"
            :enableRangeSelection="true"
            suppressMultiSort
            suppressRowClickSelection

            :enableFillHandle="false"
            :fillOperation="fillOperation"
            fillHandleDirection="xy"
            :processCellForClipboard="processCellForClipboard"
            :processCellFromClipboard="processCellFromClipboard"
            :navigateToNextCell="navigateToNextCell"
            :tabToNextCell="tabToNextCell"
            @cell-key-down="onCellKeyDown"
            @paste-start="onPasteStart"
            @paste-end="onPasteEnd"
            @cell-focused="cellFocused"
            :rowData="rowData"
            
            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"

            :overlayLoadingTemplate="overlayLoadingTemplate"

            @cell-editing-started="onCellEditingStarted"
            @cell-editing-stopped="onCellEditingStopped"
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.close') }}</b-button>
      </template>
    </b-modal>

    <SandboxModal v-if="allowManage && editorShow" 
      :data="editData"
      :id="editData.uuId"
      :sharing="true"
      :showOwner="true"
      :show.sync="editorShow" 
      @success="editorSuccess" 
      :title="editorTitleLabel"
      :sandboxList="sandboxProfile.sandboxs ? sandboxProfile.sandboxs : []"
      :appendAfterOptions="editorAppendAfterOptions"
    />


    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>

    <b-modal :title="$t('entity_selector.confirm_deletion_title')"
        v-model="ecConfirmDeleteShow"
        @hidden="ecConfirmDeleteShow=false"
        @ok="ecConfirmDeleteOk"
        @cancel="ecConfirmDeleteCancel"
        content-class="entity-delete-modal shadow"
        no-close-on-backdrop
        >
      
      <p>{{ ecConfirmDeleteStatement }}</p>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="ecShowApplyAllCheckbox" class="apply-to-all" v-model="entityCol.applyAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import 'ag-grid-enterprise'
import { AgGridVue } from 'ag-grid-vue'
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
moment.locale(locale);

import alertStateEnum from '@/enums/alert-state'
import { cloneDeep } from 'lodash'
import { strRandom, objectClone, EventBus } from '@/helpers'

import { columnDefSortFunc } from '@/views/management/script/common'
import { fieldValidateUtil } from '@/script/helper-field-validate'

import { viewProfileService, managementService, sandboxService
, profileService, fileService, userService } from '@/services'

import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink'
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime'
import RowSelectorCellRenderer from '@/components/Aggrid/CellRenderer/SelectorRowSelector'
import SelectorHeaderComponent from '@/components/Aggrid/CellHeader/SelectorRangeSelection'
import EnumCellRenderer from '@/components/Aggrid/CellRenderer/Enum'
import GenericEntityArrayCellRenderer from '@/components/Aggrid/CellRenderer/GenericEntityArray'
import GenericCellRenderer from '@/components/Aggrid/CellRenderer/Generic'
import AppendAfterCellRenderer from '@/components/Aggrid/CellRenderer/AppendAfter'

import NameEditor from '@/components/Aggrid/CellEditor/Name'
import StringEditor from '@/components/Aggrid/CellEditor/String'
import NumericEditor from '@/components/Aggrid/CellEditor/Numeric'
import DateTimeEditor from '@/components/Aggrid/CellEditor/DateTime'
import TagEditor from '@/components/Aggrid/CellEditor/Tag'
import ListEditor from '@/components/Aggrid/CellEditor/List'
import AppendAfterEditor from '@/components/Aggrid/CellEditor/AppendAfter'

import MultilineEditor from '@/components/Aggrid/CellEditor/Multiline'

import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows'



const operationStatus = {
  SUCCESS: 'SUCCESS'
  , ABORT: 'ABORT'
}
Object.freeze(operationStatus)
const operationStatusKeys = Object.keys(operationStatus)

const CELL_COPY_CODE = '_COPIED_OBJ='

export default {
  name: 'SandboxSelectorModalForAdmin'
  // , emits: ['ok', 'cancel']
  , components: {
    'ag-grid-vue': AgGridVue
    , AlertFeedback: () => import('@/components/AlertFeedback')
    , InProgressModal: () => import('@/components/modal/InProgressModal')
    , SandboxModal: () => import('@/components/modal/SandboxModal')

    //aggrid cell renderer/editor/header/Overlay component
    /* eslint-disable vue/no-unused-components */
    //Renderer
    , detailLinkCellRenderer: DetailLinkCellRenderer
    , dateTimeCellRenderer: DateTimeCellRenderer
    , rowSelectorCellRenderer: RowSelectorCellRenderer
    , selectorHeaderComponent: SelectorHeaderComponent
    , enumCellRenderer: EnumCellRenderer
    , genericEntityArrayCellRenderer: GenericEntityArrayCellRenderer
    , genericCellRenderer: GenericCellRenderer
    , appendAfterCellRenderer: AppendAfterCellRenderer
    //Editor
    , nameEditor: NameEditor
    , stringEditor: StringEditor
    , numericEditor: NumericEditor
    , dateTimeEditor: DateTimeEditor
    , tagEditor: TagEditor
    , listEditor: ListEditor
    , appendAfterEditor: AppendAfterEditor
    , multilineEditor: MultilineEditor
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  }
  , props: {
    show: {
      type: Boolean
      , required: true
    }
    //----label----
    , selectorTitle: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      elemId: 'SANDBOX_SELECTOR_FOR_ADMIN'
      , allowManage: true //always true. It is referenced in  DetailLink.vue

      , alertObj: {
        msg: null
        , msgDetails: { title: null, list: [] }
        , state: alertStateEnum.SUCCESS

      }
      , gridOptions: null
      , gridApi: null
      , selectorShow: false
      , searchFilter: ''

      , editorShow: false
      , editorAppendAfterOptions: []
      , entityId: null
      
      , coloring: {}

      , duplicateShow: false
      , duplicateName: null
      , duplicateFirstname: null
      , duplicateLastname: null
      , duplicateEmail: null
      , duplicateInProgress: false
      
      , docImportShow : false
      , existingCompanies: null
      , docImportProperties : []
      , entityMap: {}

      , inProgressShow: false
      , inProgressLabel: null
      , inProgressStoppable: false
      , inProgressState: {
        cancel: false
      }

      , rowSelectorClicked_allColsSelectedRowIndexList: []
      , rangeSelection: []
      , entitySelection: []

      , pendingListByFillOperation: []
      , triggeredByFillOperation: false
      , processValueChangedList: []
      , processDateValueChangedList: []
      , pendingProcessRequestList: []
      , pendingRequestBatchList: []
      , pendingDeleteCells: []

      , ecConfirmDeleteShow: false
      , ecConfirmDeleteEntities: []
      , entityCol: {
        entityName: null
        , parentName: null
        , entityId: null
        , parentId: null
        , colId: null
        , applyAll: false
      }

      , rowData: null

      , noRowsMessage: null
      , noRowsOverlayComponentParams: null
      , isCellEditing: false
      , lastOpenColumnMenuParams: null
      , entities: []
      , entity: null
      , propertyList: {}
      , inUse: {}

      , sandboxProfile: {}
      , editData: { uuId: null, name: null }
    }
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const saveSelectorColumnSettings = (columns, skipCheck=true) => {
      if (this.profileKeySelector != null) {
        const newColumns = columns.filter(c => c.colId != 'rowSelector').map(c => getColumnDefs(c))
        const oldColumns = this.profileSettings[this.profileKeySelector]
        if (skipCheck) {
          this.profileSettings[this.profileKeySelector] = newColumns
          this.updateViewProfile()
          return
        }

        let hasChanged = false
        if (oldColumns == null) {
          hasChanged = true
        } else if (oldColumns.length != newColumns.length) {
          hasChanged = true
        } else {
          for (const [index, col] of oldColumns.entries()) {
            if (col.colId != newColumns[index].colId || 
                col.width != newColumns[index].width ||
                col.sort != newColumns[index].sort ||
                col.sortIndex != newColumns[index].sortIndex) {
              hasChanged = true
              break
            }
          }
        }
        if (hasChanged) {
          this.profileSettings[this.profileKeySelector] = newColumns
          this.updateViewProfile()
        }
      }
    }

    const self = this
    // this.gridOptions = {}
    
    this.columnDefs = []
    this.autoGroupColumnDef = {};
    
    this.gridOptions = {
      isExternalFilterPresent: function() {
        return self.searchFilter.length > 0;
      },
      doesExternalFilterPass: function doesExternalFilterPass(node) {
        if (node.data) {
          return node.data.name.toLowerCase().indexOf(self.searchFilter.toLowerCase()) !== -1 ||
            node.data.displayName.toLowerCase().indexOf(self.searchFilter.toLowerCase()) !== -1;
        }
        return false;
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.api
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.api.moveColumns([colKey], columnMenuColumnIndex + 1);
        }
        const cols = params.api.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.api.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.api.moveColumns([c.colId], index);
        }

        const columns = params.api.getAllDisplayedColumns()
        saveSelectorColumnSettings(columns)
      }
      , postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      }
      , onSortChanged: function(event) {
        //Clear last focused cell. Reset vertical scroll position to top. Clear range selection
        self.lastFocusedCell = null
        self.previousVScrollPosition = null
        event.api.clearRangeSelection()
        if (event.api.gridBodyCtrl && event.api.gridBodyCtrl.bodyScrollFeature) {
          event.api.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(0)
        }
        //Update column setting
        const columns = event.api.getAllDisplayedColumns()
        saveSelectorColumnSettings(columns)
      }
      , onDragStopped: function(event) {
        const columns = event.api.getAllDisplayedColumns()
        saveSelectorColumnSettings(columns, false)
      }
      , onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null
          event.api.sizeColumnsToFit()
          self.$nextTick(() => {
            const columns = event.api.getAllDisplayedColumns()
            saveSelectorColumnSettings(columns)
          })
        }
      }

      , onCellValueChanged: function(event) {
        let colId = event.column.colId
        const rowIndex = event.rowIndex
        let newValue = event.newValue
        let oldValue = event.oldValue
        const rowNode = event.api.getDisplayedRowAtIndex(rowIndex)
        
        const payload = {
          colId
          , data: objectClone(rowNode.data)
          , newValue
          , oldValue
          , property: colId
          , entityId: rowNode.id
          , parentId: rowNode.data.pUuId
          , entityName: rowNode.data.name
        }
        
        if (colId === 'max' && newValue === null) {
          payload.newValue = 32503680000000;
        }
        else if (colId === 'min' && newValue === null) {
          payload.newValue = 0;
        }
        
        if (colId === self.COLUMN_AGGRID_AUTOCOLUMN || payload.newValue == payload.oldValue) {
          //do nothing
        } else if (self.triggeredByFillOperation) {
          self.pendingListByFillOperation.push(payload)
        } else if (self.isPasteInProgress) {
          self.processValueChangedList.push(payload)
        } else {
          self.processValueChangedList.push(payload)
          self.inProgressLabel = self.$t(`entity_selector.${self.formattedEntity}_update_progress`)
          self.processValueChanged(event.api)
        }
      }

      , onRangeSelectionChanged: function(event) {
        if (event.finished == true) {
          if (self.triggeredByFillOperation) {
            const pendingList = self.pendingListByFillOperation.splice(0, self.pendingListByFillOperation.length)
            self.processValueChangedList.push(...pendingList)
            self.consolidateChangedCellValues(self.processValueChangedList)
            self.triggeredByFillOperation = false
            self.inProgressLabel = self.$t(`entity_selector.${self.formattedEntity}_update_progress`)
            self.processValueChanged(event.api)
          }
          const cellRanges = event.api.getCellRanges()
          const originalRanges = cellRanges.map(i => {
            return {
              rowStartIndex: i.startRow.rowIndex,
              rowEndIndex: i.endRow.rowIndex,
              columns: i.columns.map(j => j.colId)
            }
          })
          if (cellRanges.length > 0) {
            const lastRange = (() => {
                const tmp = cellRanges[cellRanges.length-1]
                const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex
                return {
                  rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
                  rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
                  columns: tmp.columns.map(i => i.colId),
                  startColumn: tmp.startColumn ? tmp.startColumn.colId : null,
                  orgRowStartIndex: tmp.startRow.rowIndex,
                  orgRowEndIndex: tmp.endRow.rowIndex
                }
              })()
            const lastColumnId = lastRange.startColumn == lastRange.columns[0]? lastRange.columns[lastRange.columns.length-1] : lastRange.columns[0] 
            //Handle drag selection which starts (mouse click press) or finishes (mouse click release) on rowSelector column. 
            //Brief: Include all cells of the selected rows into the cell ranges as if user clicks on rowSelector columns
            if (lastRange.columns.length > 1 && (lastColumnId == 'rowSelector' || lastRange.startColumn == 'rowSelector')) {
              self.rowSelectorChanged({
                cellRanges,
                originalRanges,
                lastRange,
                api: event.api
              })
            } else {
              //Brief: Reshape previous ranges to avoid clashing with last/latest range.
              const lastRangeColumns = lastRange.columns
              const isLastRangeAsc = lastRange.rowStartIndex <= lastRange.rowEndIndex
              const lastRangeStartIndex = isLastRangeAsc? lastRange.rowStartIndex : lastRange.rowEndIndex
              const lastRangeEndIndex = isLastRangeAsc? lastRange.rowEndIndex : lastRange.rowStartIndex

              const cRanges = cellRanges.map(i => {
                return {
                  rowStartIndex: i.startRow.rowIndex,
                  rowEndIndex: i.endRow.rowIndex,
                  columns: i.columns.map(j => j.colId)
                }
              })
              cRanges.splice(cRanges.length - 1, 1)
              const newCellRanges = []
              let hasChanges = false

              //Before reshape, check if last range's start cell clashes with previous cell range.
              //If yes, do nothing (and expect aggrid will do its job to remove cell ranges)
              const startColumnId = lastRange.startColumn
              const startCell = {
                rowStartIndex: lastRange.orgRowStartIndex,
                rowEndIndex: lastRange.orgRowStartIndex
              }
              let isStartCellClashed = false
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
                const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex
                const columnClashed = range.columns.filter(i => i == startColumnId).length > 0
                if (isRowClashed && columnClashed) {
                  isStartCellClashed = true
                  break
                }
              }
              if (isStartCellClashed) {
                return
              }

              //Reshape previous ranges to avoid clashing with last/latest range.
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
                const isRowClashed = (lastRangeStartIndex >= cellRangeStartIndex && lastRangeStartIndex <= cellRangeEndIndex) ||
                                     (cellRangeStartIndex >= lastRangeStartIndex && cellRangeStartIndex <= lastRangeEndIndex)
                const clashedColumns = range.columns.filter(i => lastRangeColumns.includes(i))                
                if (!isRowClashed || clashedColumns.length == 0) {
                  newCellRanges.push(range)
                  continue
                }
                
                //Reshape cell range if it clash with lastRange.
                const splitTopNeeded = cellRangeStartIndex < lastRangeStartIndex
                const splitBottomNeeded = cellRangeEndIndex > lastRangeEndIndex
                const firstClashedCol = clashedColumns[0]
                const splitLeftNeeded = range.columns.findIndex(i => i == firstClashedCol) != 0
                const lastClashedCol = clashedColumns[clashedColumns.length-1]
                const splitRightNeeded = range.columns.findIndex(i => i == lastClashedCol) != range.columns.length-1

                if (splitTopNeeded) {
                  newCellRanges.push({
                    rowStartIndex: cellRangeStartIndex,
                    rowEndIndex: lastRangeStartIndex-1,
                    columns: objectClone(range.columns)
                  })
                  hasChanges = true
                }

                if (splitRightNeeded) {
                  const startColIdx = range.columns.findIndex(i => i == lastClashedCol)+1
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(startColIdx)
                  })
                  hasChanges = true
                }

                if (splitBottomNeeded) {
                  newCellRanges.push({
                    rowStartIndex: lastRangeEndIndex+1,
                    rowEndIndex: cellRangeEndIndex,
                    columns: objectClone(range.columns)
                  })
                  hasChanges = true
                }

                if (splitLeftNeeded) {
                  const sliceLen = range.columns.findIndex(i => i == firstClashedCol)
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(0, sliceLen)
                  })
                  hasChanges = true
                }
              }
              
              //Merge last range to any existing range when condition met.
              //Conditions: 1) Matched rows and column(s) in sequence, or 
              //            2) Matched columns and row(s) in sequence.
              let hasRangeMerged = false
              if (newCellRanges.length > 0) {
                const allColumns = event.api.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
                const lastRowStartColIndex = allColumns.findIndex(i => i == lastRangeColumns[0])
                const lastRowEndColIndex = allColumns.findIndex(i => i == lastRangeColumns[lastRangeColumns.length - 1])
                const cloned = objectClone(newCellRanges)
                const newRanges = []
                for (const cRange of cloned) {
                  const startColIndex = allColumns.findIndex(i => i == cRange.columns[0])
                  const endColIndex = allColumns.findIndex(i => i == cRange.columns[cRange.columns.length - 1])
                  const isRowIndexMatched = cRange.rowStartIndex == lastRangeStartIndex && cRange.rowEndIndex == lastRangeEndIndex
                  const isColumnMatched = startColIndex == lastRowStartColIndex && endColIndex == lastRowEndColIndex
                  const isRowInSequence = cRange.rowStartIndex -1 == lastRange.rowEndIndex || cRange.rowEndIndex + 1 == lastRange.rowStartIndex
                  const isColumnInSequence = startColIndex - 1 == lastRowEndColIndex || endColIndex + 1 == lastRowStartColIndex
                  if (isRowIndexMatched && isColumnInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex
                      , rowEndIndex: lastRangeEndIndex
                      , columns: lastRowStartColIndex < startColIndex? [...lastRangeColumns, ...cRange.columns] : [...cRange.columns, ...lastRangeColumns]
                    })
                    hasRangeMerged = true
                    continue
                  } else if (isColumnMatched && isRowInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex < cRange.rowStartIndex? lastRangeStartIndex : cRange.rowStartIndex
                      , rowEndIndex: lastRangeEndIndex > cRange.rowEndIndex? lastRangeEndIndex : cRange.rowEndIndex
                      , columns: lastRangeColumns
                    })
                    hasRangeMerged = true
                    continue
                  }
                  newRanges.push(cRange)
                }
                if (hasRangeMerged) {
                  newCellRanges.splice(0, newCellRanges.length, ...newRanges)
                }
              }
              
              //hasChanges flag is important to avoid infinite loop. 
              //any addCellRange() call will trigger onRangeSelectionChange() event.
              //Don't call addCellRange() when no change is required.
              if (hasChanges || hasRangeMerged) {
                //Adding last range when hasRangeMerged is false. 
                //Details: If hasRangeMerged is true, the last range has been merged to one of the previous range.
                if (!hasRangeMerged) {
                  newCellRanges.push({
                    rowStartIndex: lastRange.rowStartIndex,
                    rowEndIndex: lastRange.rowEndIndex,
                    columns: lastRange.columns
                  })
                }
                event.api.clearRangeSelection()
                for (const ncRange of newCellRanges) {
                  event.api.addCellRange(ncRange)
                }
              } else {
                //When thing settles down, update entitySelection variable.
                let selectedEntities = []
                for (const oRange of originalRanges) {
                  const startRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowEndIndex : oRange.rowStartIndex
                  const endRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowStartIndex : oRange.rowEndIndex
                  
                  for (let i = startRowIdx; i <= endRowIdx; i++) {
                    const rowNode = event.api.getDisplayedRowAtIndex(i)
                    
                    if (rowNode == null) {
                      continue
                    }

                    //Treat any cell selection is ag-Grid-AutoColumn cell selection.
                    selectedEntities.push({ ...rowNode.data
                      , colId: self.COLUMN_AGGRID_AUTOCOLUMN
                      , rowIndex: rowNode.rowIndex
                    })
                  }
                }
                
                //Rearrange order of the tasks.
                //Tasks without rowIndex will be pushed to the bottom of the list. Theorectically, all tasks should have rowIndex property.
                //The first task/summary task will be target parent for new task creation.
                selectedEntities.sort(function( a, b ) {
                  if (a.rowIndex == null && b.rowIndex == null) {
                    return 0
                  }
                  if (b.rowIndex == null || a.rowIndex < b.rowIndex){
                    return -1
                  }
                  if (a.rowIndex == null || a.rowIndex > b.rowIndex){
                    return 1
                  }
                  return 0
                })
                
                self.entitySelection.splice(0, self.entitySelection.length, ...selectedEntities)
              }
            }
          } else {
            //Clean up entitySelection when range selection is empty.
            self.entitySelection.splice(0, self.entitySelection.length)
          }
          event.api.refreshHeader()
        }
        
      }
      , onPaginationChanged: function(/** event */) {
        self.previousVScrollPosition = null
      }
    }
    this.context = { componentParent: self }
    this.defaultColDef = {
      sortable: true
      , resizable: true
      , minWidth: 100
      , hide: true
      , lockPinned: true
      , menuTabs: ['columnsMenuTab']
      , suppressKeyboardEvent: (params) => {
        if (params.event.keyCode === 46 
            || params.event.keyCode === 35 || params.event.keyCode == 36 
            || (params.event.keyCode == 37 && params.event.ctrlKey)) {
          return true
        } else if (params.event.keyCode == 13) {
          if (params.event.ctrlKey) {
            return true
          }
          if ((typeof params.colDef.editable == 'boolean' && !params.colDef.editable) || 
              (typeof params.colDef.editable == 'function' &&  !params.colDef.editable({ data: params.data }))) {
            return true
          }
        } else if (params.event.keyCode == 68 && params.event.ctrlKey) { //'D'
          return true
        }
        return false
      }
      , cellRendererParams: { enableReadonlyStyle: true }
    }
    
  },
  created() {
    this.formattedEntity = 'sandbox'
    this.COLUMN_AGGRID_AUTOCOLUMN = 'ag-Grid-AutoColumn'
    this.profileKeySelector = 'field_admin_selector_list'
    this.cellCopyPrefix = `${this.entity}${CELL_COPY_CODE}`
    this.dataGroup = {
      stringGroup: ['name', 'identifier']
    }
    this.modelInfo = null
    this.typeOptions = []
    this.profileSettings = null
    this.newToProfile = null
    this.exportData = false
    this.lastFocusedCell = null
    this.previousVScrollPosition = null
    this.isPasteInProgress = false
    this.mandatoryFields = ['name', 'type']
    this.toggleSelectorShow(this.show)
    document.addEventListener('keydown', this.keyDownHandler)
    

    const colorOptions = {
      none: true
      , company: false
      , location: false
    }
    
    Object.keys(colorOptions).forEach(key => this.coloring[key] = colorOptions[key])

    this.docImportProperties.splice(0, this.docImportProperties.length)

    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
  },
  beforeDestroy() {
    this.isPasteInProgress = false
    this.previousVScrollPosition = null
    this.cellCopyPrefix = null
    this.lastFocusedCell = null
    this.exportData = false
    this.userId = null
    this.newToProfile = null
    this.profileSettings = null
    this.profileKeySelector = null
    this.COLUMN_AGGRID_AUTOCOLUMN = null
    this.autoGroupColumnDef = null
    this.columnDefs = null
    this.typeOptions = null
    this.modelInfo = null
    this.dataGroup = null
    this.entity = null
    this.formattedEntity = null
    this.mandatoryFields = null
    document.removeEventListener('keydown', this.keyDownHandler)
    this.gridApi = null
  },
  watch: {
    async show(newValue) {
      if (newValue) {
        this.init() 
      }
      
      //Reset value
      this.resetAlert()
      this.entityId = null
      this.searchFilter = ''
      this.entitySelection.splice(0, this.entitySelection.length)
      this.rangeSelection.splice(0, this.rangeSelection.length)
      this.lastFocusedCell = null
      this.previousVScrollPosition = null
      this.isPasteInProgress = false
      this.isCellEditing = false
      await this.getModelInfo(this.entity)
      if(newValue) {
        this.init()
      }
      this.toggleSelectorShow(newValue)
    },
  },
  computed: {
    editorTitleLabel() {
      if (this.editData && this.editData.uuId === null) {
        return this.$t(`sandbox.title_new`)
      }
      return this.$t(`sandbox.title_edit`)
    }
    , selectorTitleLabel() {
      return this.selectorTitle != null? this.selectorTitle : this.$t(`entity_selector.${this.formattedEntity}_selector`)
    }
    , duplicateTitleLabel() {
      return this.duplicateTitle != null? this.duplicateTitle : this.$t(`entity_selector.${this.formattedEntity}_duplicate`)
    }
    , duplicateFieldNameLabel() {
      return this.$t(`entity_selector.${this.formattedEntity}_duplicate_field_name`)
    }
    , showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name')
    }
    , colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter'
    }
    , disableEdit() {
      for (const entity of this.entitySelection) {
        if (entity.id === "0") {
          return true; // disable delete on english
        }
      }
      return this.entitySelection.length != 1
    }
    , disableDuplicate() {
      return this.entitySelection.length != 1
    }
    , disableDelete() {
      return this.entitySelection.length < 1
    }
    , disableExport() {
      return this.entitySelection.length != 1
    }
    , ecShowApplyAllCheckbox() {
      return this.ecConfirmDeleteEntities.length > 0
    }
    , ecConfirmDeleteStatement() {
      return this.$t('sandbox.delete');
    }
    , overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('sandbox.grid.loading_list') }</span>`;
    }
    // , appendAfterOptions() {
    //   return this.entity != null
    //     ? 
    // }
  },
  methods: {
    async init() {
        
      const canEdit = true
      const colDefs = [
        this.getRowSelectorColumn(),
        {
          headerName: this.$t('sandbox.field.name')
          , field: 'name'
          , hide: false
          , pinned: 'left'
          , minWidth: 100
          , lockVisible: true
          , cellRenderer: 'detailLinkCellRenderer'
          , cellRendererParams: { label: 'name' }
          , editable: false
        },
        {
          headerName: this.$t('sandbox.field.owner')
          , field: 'ownerName'
          , editable: false
          , hide: false
        },
        {
          headerName: this.$t('sandbox.field.created')
          , field: 'createdAt'
          , cellRenderer: 'dateTimeCellRenderer'
          , editable: false
          , hide: false
        }
      ]

      colDefs.sort(columnDefSortFunc)
      this.columnDefs = colDefs
      
      await this.loadViewProfile()
    }
    , onGridReady(params) {
      this.gridApi = params.api
      this.reloadData()
    }
    , toggleSelectorShow(newValue) {
      this.selectorShow = newValue
    }
    , hidden() {
      this.alert = null
      this.inProgressShow = false;
      this.$emit('update:show', false)
      this.$emit('cancel')
    }
    , openEditor(isNew) {
      
      if(isNew) {
        this.editData.name = null;
        this.editData.uuId = null;
        this.editData.viewBy = [];
        this.editData.editBy = [];
        this.editData.owner = null;
        this.editData.createdAt = null;
      } else {
        this.editData.name = this.entitySelection[0].name
        this.editData.owner = this.entitySelection[0].owner
        this.editData.viewBy = this.entitySelection[0].viewBy
        this.editData.editBy = this.entitySelection[0].editBy
        this.editData.uuId = this.entitySelection[0].uuId
        this.editData.createdAt = this.entitySelection[0].createdAt
      }
      const records = [];
      this.gridApi.forEachNode(node => records.push(node.data));
      this.editorShow = true
      this.resetAlert()
    }
    , async editorSuccess(payload) { 
      if (this.gridApi == null) {
        return
      }
      
      this.reloadData()
    }
    , detailLinkId(params) {
      return params.data.uuId;
    }
    , openDetail(id){
      this.editData = cloneDeep(this.gridApi.getRowNode(id).data);
      this.editorShow = true
      this.resetAlert()
    }
    , resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertObj.msg = msg
      this.alertObj.state = alertState
      this.alertObj.msgDetails.title = detailTitle
      const list = this.alertObj.msgDetails.list
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details)
      } else {
        list.splice(0, list.length)
      }
    }
    // , getEditorComponent(entity) {
    //   switch(entity) {
    //     case 'COMPANY':
    //       return CTagModal
    //     default: 
    //       return null
    //   }
    // }
    , updateViewProfile() {
      viewProfileService.update([this.profileSettings], this.userId)
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , createViewProfile() {
      viewProfileService.create([this.profileSettings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase]
        this.profileSettings.uuId = data[0].uuId
        this.newToProfile = true
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , async loadViewProfile() {
      await this.$store.dispatch('data/viewProfileList', this.userId).then((value) => {  
        const profileData = value
        if (profileData.length === 0) {
          this.createViewProfile()
        }
        else {
          this.profileSettings = profileData[0]
          if (typeof this.profileSettings[this.profileKeySelector] != 'undefined') {
            this.loadColumnSettings(this, this.profileSettings[this.profileKeySelector])
          } else {
            this.newToProfile = true
          }
        }
      })
      .catch((e) => {
        console.error(e) // eslint-disable-line no-console
      })
    }
    , loadColumnSettings(target, columns) {
      if (target == null || columns == null || columns.length == 0) {
        return
      }
      // order the columns based upon the order in 'columns'
      // 0 index column is reserved for rowSelector
      let idx = 1
      columns.forEach(function(col) {
        const index = target.columnDefs.findIndex((c) => c.field === col.colId)
        if (index !== -1) {
          target.columnDefs.splice(idx++, 0, target.columnDefs.splice(index, 1)[0])
        }
      })

      for (const column of target.columnDefs) {
        const setting = columns.filter(c => c.colId === column.colId || c.colId === column.field)
        if (setting.length === 0) {
          if (column.colId != 'rowSelector') {
            column.hide = true
          }
        }
        else {
          column.hide = false
          column.width = setting[0].width
          column.sort = setting[0].sort
          column.sortIndex = setting[0].sortIndex
        }
      }
      
      if (target.gridApi != null) {
        target.gridApi.setGridOption('columnDefs', cloneDeep(target.columnDefs))
      }
      return
    }
    , async getModelInfo(entity) {
      if (entity === null) {
        return;
      }
      
      await this.$store.dispatch('data/info', {type: 'api', object: entity}).then(value => {
        this.modelInfo = value[entity].properties
      })
      .catch(e => {
        this.httpAjaxError(e)
      })
    }
    , showDuplicateDialog() {
      this.resetAlert()
      const data = this.gridApi.getRowNode(this.entitySelection[0].uuId).data
      if (this.entity == 'USER') {
        this.duplicateFirstname = data.firstName
        this.duplicateLastname = data.lastName
        this.duplicateEmail = ''
      } else {
        const origName = data.name
        this.duplicateName = `${origName} ${this.$t('entity_selector.duplicate_name_suffix')}`
      }
      this.duplicateShow = true
    }
    , duplicateOk() {
      this.duplicateEntity()
    }
    , scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.elemId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
    , rowDelete() {
      this.resetAlert()
      const targetColEntities = []

      for (const task of this.entitySelection) {
        const rowNode = this.gridApi.getRowNode(task.uuId)
        if (rowNode == null) {
          continue
        }
        targetColEntities.push({
          id: rowNode.id
          , name: rowNode.data.name
          , parent: rowNode.data.pUuId
          , parentName: rowNode.data.pName
          , colId: this.COLUMN_AGGRID_AUTOCOLUMN
        })
      }

      if (targetColEntities.length > 0) {
        //Prepare data for taskcol delete confirmation dialog
        this.ecConfirmDeleteEntities = targetColEntities
        this.prepareTargetColConfirmDeleteDialog()
      }
    }
    , async addEntities(items, parent) {
      this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_import_progress`, [0])
      let percentage = 0
      const self = this
      const errorFunc = (e) => {
        self.httpAjaxError(e)
      }
      
      
      for (const item of items) {
        item.parent = parent
        await this.importData(item, errorFunc, self)
        percentage++
        this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_import_progress`, [parseFloat(percentage / items.length * 100).toFixed(0)])
      }
    }
    , fileExport() {
      this.inProgressShow = true
      this.inProgressLabel = this.$t('dataview.exporting')
      this.exportData = true
      
      let listener = () =>{
        const keys = this.gridApi
          .getColumns()
          .filter(column => column.getColId() != 'rowSelector' && column.getColId() != 'path')
          .map(column => column.getColId())
        keys.unshift(this.COLUMN_AGGRID_AUTOCOLUMN)
        this.gridApi.exportDataAsExcel({ 
          fileName: this.$t(`entity_selector.${this.formattedEntity}_export_filename`)
          , sheetName: this.$t(`entity_selector.${this.formattedEntity}_export_sheetname`)
          , columnKeys: keys
          , rowHeight: 20
          , processCellCallback: this.processCellCallback()
        })
      
        this.exportData = false
        this.inProgressShow = false
      }
      listener()
    }
    , processCellCallback() {
      const propertyHandler = this.getExportDataPropertyHandler(this)
      const self = this
      return function(params) {
        if (propertyHandler != null && Object.hasOwn(propertyHandler, params.column.colId)) {
          return propertyHandler[params.column.colId](params)
        } else 
        if (params.column.colId.indexOf(self.COLUMN_AGGRID_AUTOCOLUMN) !== -1) {
          return params.node.data.name
        }
        return params.value
      }
    }
    , isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
      const mq = function (query) {
          return window.matchMedia(query).matches
      }
      if ('ontouchstart' in window) {
          return true
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
      return mq(query)
    }
    , getRowSelectorColumn() {
      return {
        headerName: ''
        , colId: 'rowSelector'
        , width: 48
        , minWidth: 48
        , maxWidth: 48
        , hide: false
        , cellRenderer: 'rowSelectorCellRenderer'
        , cellRendererParams: {
          isReadOnly: false,
          enableReadonlyStyle: true
        }
        , pinned: 'left'
        , lockPosition: 'left'
        , lockVisible: true
        , suppressColumnsToolPanel: true

        , menuTabs: ['generalMenuTab']
        , resizable: false
        , headerComponent: 'selectorHeaderComponent'
        , headerComponentParams: {
          nameColumn: 'name'
        }
        , suppressFillHandle: true 
        , rowDrag: false
      }
    }
    //Referenced in RowSelector.vue
    , rowSelectorMouseDown(rowIndex=null) {
      if (rowIndex == null) {
        return
      }
      
      //Consolidate all ranges's row and column details into rowColumnMap 
      const rowColumnMap = new Map()
      const cellRanges = this.gridApi.getCellRanges()
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex
        const rowEndIndex = cRange.startRow.rowIndex > cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
        const columns = cRange.columns.map(i => i.colId)
        if (rowStartIndex == rowEndIndex) {
          if (!rowColumnMap.has(rowStartIndex)) {
            rowColumnMap.set(rowStartIndex, new Set())
          }
          const rCol = rowColumnMap.get(rowStartIndex)
          for (const col of columns) {
            if (col == 'rowSelector') {
              continue
            }
            rCol.add(col)
          }
          continue
        }

        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          if (!rowColumnMap.has(i)) {
            rowColumnMap.set(i, new Set())
          }
          const rCol = rowColumnMap.get(i)
          for (const col of columns) {
            rCol.add(col)
          }
        }
      }

      const maxColumnsLength = this.gridApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').length
      //Reset list
      this.rowSelectorClicked_allColsSelectedRowIndexList.splice(0, this.rowSelectorClicked_allColsSelectedRowIndexList.length)
      //Check which row has full set of columns in range selection.
      //When the row has full set, add it to tobeUnselected list
      for (let [key, value] of rowColumnMap) {
        if (value.size == maxColumnsLength) {
          this.rowSelectorClicked_allColsSelectedRowIndexList.push(key)
        }
      }
    }
    //Referenced in RowSelector.vue
    , rowSelectorMouseUp({ ctrlKey=false, shiftKey=false, rowIndex=null } = {}) {
      const cellRanges = this.gridApi.getCellRanges()
      const originalRanges = cellRanges.map(i => {
        return {
          rowStartIndex: i.startRow.rowIndex,
          rowEndIndex: i.endRow.rowIndex,
          columns: i.columns.map(j => j.colId)
        }
      })

      //Handle case when shift key is pressed and click on row selector
      if (shiftKey == true && rowIndex != null) {
        const focusedCell = this.gridApi.getFocusedCell()
        const focusedCellRowIndex = focusedCell.rowIndex
        const focusedCellColId = focusedCell.column.colId

        let cellRanges = this.gridApi.getCellRanges()
        cellRanges = cellRanges.map(i => {
          return {
            rowStartIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.endRow.rowIndex : i.startRow.rowIndex,
            rowEndIndex: i.startRow.rowIndex > i.endRow.rowIndex? i.startRow.rowIndex : i.endRow.rowIndex,
            columns: i.columns.map(i => i.colId)
          }
        })
        const newRanges = []
        
        let isDirectionUp = rowIndex < focusedCellRowIndex
        let newStartRowIndex = rowIndex > focusedCellRowIndex? focusedCellRowIndex : rowIndex
        let newEndRowIndex = rowIndex > focusedCellRowIndex? rowIndex : focusedCellRowIndex
        
        //Handle case when both shift key and ctrl key are pressed
        if (ctrlKey == true) {
          //Remove last range if there is any. New range will be created as replacement later.
          if (cellRanges.length > 0) {
            cellRanges.splice(cellRanges.length-1 , 1)
          }

          //Reshape previous ranges to avoid new range created between the row which last focused cell resides and the row which user click
          for (const cRange of cellRanges) {
            const isClashed = (newStartRowIndex >= cRange.rowStartIndex && newStartRowIndex <= cRange.rowEndIndex) ||
                                (cRange.rowStartIndex >= newStartRowIndex && cRange.rowStartIndex <= newEndRowIndex)
            
            if (!isClashed) {
              //Transfer the range to newCellRanges when there is no row clashed with last range.
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
              continue
            }

            //split existing range to exclude the last range's selected rows.
            if (cRange.rowStartIndex < newStartRowIndex && cRange.rowEndIndex >= newStartRowIndex) {
              newRanges.push({
                rowStartIndex: cRange.rowStartIndex,
                rowEndIndex: newStartRowIndex-1,
                columns: cRange.columns
              })
            }
            if (cRange.rowEndIndex > newEndRowIndex && cRange.rowStartIndex <= newEndRowIndex) {
              newRanges.push({
                rowStartIndex: newEndRowIndex+1,
                rowEndIndex: cRange.rowEndIndex,
                columns: cRange.columns
              })
            }
          }
        }

        //New range replacing last range if there is any
        newRanges.push({
          rowStartIndex: isDirectionUp? newEndRowIndex: newStartRowIndex,
          rowEndIndex: isDirectionUp? newStartRowIndex : newEndRowIndex,
          columns: this.gridApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })

        this.gridApi.clearRangeSelection()
        for (const nRange of newRanges) {
          this.gridApi.addCellRange(nRange)
        }
        this.gridApi.setFocusedCell(focusedCellRowIndex, focusedCellColId)
        return
      }

      //Handle edge case: shift click rowSelector when there is no existing range selection.
      if (cellRanges.length == 0) {
        const curFocusedCell = this.gridApi.getFocusedCell()
        if (curFocusedCell == null) {
          this.gridApi.clearFocusedCell()
          return
        }
        const rowIndex = this.gridApi.getFocusedCell().rowIndex
        this.gridApi.addCellRange({
          rowStartIndex: rowIndex,
          rowEndIndex: rowIndex,
          columns: this.gridApi.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
        })
        this.gridApi.setFocusedCell(rowIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null)
        return
      }

      const lastRange = (() => {
        const tmp = cellRanges[cellRanges.length-1]
        const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex
        return {
          rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
          rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
          columns: tmp.columns.map(i => i.colId),
          startColumn: tmp.startColumn.colId,
          orgRowStartIndex: tmp.startRow.rowIndex,
          orgRowEndIndex: tmp.endRow.rowIndex
        }
      })()

      this.rowSelectorChanged({ 
        cellRanges,
        originalRanges,
        lastRange,
        api: this.gridApi
      })
    }
    , rowSelectorChanged({ cellRanges, originalRanges, lastRange, api }) {
      //Brief: Depends on user interaction, add/remove cell range selection.
      //General Specification:
      //If startCell clashes with previous ranges, remove all cell in the new range.
      //If startCell does not clash with previous ranges, select all cells in the new range. (reshape cell in previous ranges to avoid new range).
      //If only rowSelector column in the new range,
      //   - Clash or not clash becomes irrelavant
      //   - Select all cell if not all cells are selected in the row. Otherwise, remove selected cell from any ranges.
      //   - If row count is more than one, focus on 1st row (starting row)

      if (cellRanges.length > 0) {
        const startColumnId = lastRange.startColumn
        const previousRanges = cellRanges.slice(0, cellRanges.length-1).map(i => {
          const isAsc = i.startRow.rowIndex <= i.endRow.rowIndex
          return {
            rowStartIndex: isAsc? i.startRow.rowIndex : i.endRow.rowIndex,
            rowEndIndex: isAsc? i.endRow.rowIndex : i.startRow.rowIndex,
            columns: i.columns.map(j => j.colId)
          }
        })

        // //Check is last range is single row or multiple row selection
        // const isSingleRow = lastRange.rowEndIndex - lastRange.rowStartIndex == 0
        
        //Check if last range's start cell clashes with previous cell ranges.
        const startCell = {
          rowStartIndex: lastRange.orgRowStartIndex,
          rowEndIndex: lastRange.orgRowStartIndex
        }
        let isStartCellClashed = false
        for (const range of previousRanges) {
          const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
          const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
          const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
          const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex
          const columnClashed = range.columns.filter(i => i == startColumnId).length > 0
          if (isRowClashed && columnClashed) {
            isStartCellClashed = true
            break
          }
        }

        //Determine to unselect or select row
        let toUnselect = this.rowSelectorClicked_allColsSelectedRowIndexList.includes(startCell.rowStartIndex)

        //Prepare new cell ranges for previous ranges
        const newCellRanges = []
        for (const range of previousRanges) {

          const isClashedWithLastRange = (lastRange.rowStartIndex >= range.rowStartIndex && lastRange.rowStartIndex <= range.rowEndIndex) ||
                                          (range.rowStartIndex >= lastRange.rowStartIndex && range.rowStartIndex <= lastRange.rowEndIndex)

          if (!isClashedWithLastRange) {
            //Transfer the range to newCellRanges when there is no row clashed with last range.
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
            continue
          }

          //split existing range to exclude the last range's selected rows.
          if (range.rowStartIndex < lastRange.rowStartIndex && range.rowEndIndex >= lastRange.rowStartIndex) {
            newCellRanges.push({
              rowStartIndex: range.rowStartIndex,
              rowEndIndex: lastRange.rowStartIndex-1,
              columns: range.columns
            })
          }
          if (range.rowEndIndex > lastRange.rowEndIndex && range.rowStartIndex <= lastRange.rowEndIndex) {
            newCellRanges.push({
              rowStartIndex: lastRange.rowEndIndex+1,
              rowEndIndex: range.rowEndIndex,
              columns: range.columns
            })
          }
        }

        if (!isStartCellClashed && !toUnselect) {
          //Prepare new cell range for last range
          newCellRanges.push({
            rowStartIndex: lastRange.rowStartIndex,
            rowEndIndex: lastRange.rowEndIndex,
            columns: api.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
          })
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null)
          }
        }

        if (toUnselect) {
          if (lastRange.startColumn == 'rowSelector') {
            api.setFocusedCell(lastRange.orgRowStartIndex, this.COLUMN_AGGRID_AUTOCOLUMN, null)
          }
        }
        
        //Check against original ranges. Don't update cell ranges if there is no changes. It helps to avoid infinite loop.
        if (JSON.stringify(originalRanges) != JSON.stringify(newCellRanges)) {
          api.clearRangeSelection()
          for (const newRange of newCellRanges) {
            api.addCellRange(newRange)
          }
        } else {
          const rowNodes = api.rowModel.rowsToDisplay
          const selection = new Set()
          for (const range of originalRanges) {
            let startIdx = range.rowStartIndex
            let endIdx = range.rowEndIndex
            if (startIdx > endIdx) {
              startIdx = range.rowEndIndex
              endIdx = range.rowStartIndex
            }

            for (let i = startIdx; i <= endIdx; i++) {
              selection.add(rowNodes[i].data.uuId)
            }
          }
        }
        
      }
    }
    , async processValueChanged(api, { customProgressLabel=null } = {}) {
      if (customProgressLabel != null) {
        this.inProgressLabel = customProgressLabel
      } else {
        this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_update_progress`)
      }
      this.inProgressShow = true
      
      if (this.pendingProcessRequestList.length > 250) {
        do {
          this.pendingRequestBatchList.push(this.pendingProcessRequestList.splice(0, 250))
        } while(this.pendingProcessRequestList.length > 250)
      }

      //Prepare to call compose api request and end the session
      if (this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        //Add the requests as a new batch to batch list.
        if (this.pendingProcessRequestList.length > 0) {
          const requests = this.pendingProcessRequestList.splice(0, this.pendingProcessRequestList.length)
          this.pendingRequestBatchList.push(requests)
        }

        //Process the request batch
        if (this.pendingRequestBatchList.length > 0) {
          const bList = this.pendingRequestBatchList.splice(0, this.pendingRequestBatchList.length)
          this.resetAlert()

          this.previousVScrollPosition = this.gridApi.getVerticalPixelRange().top

          this.reloadData(() => {
            setTimeout(() => {
              this.resetFocus(api, this.lastFocusedCell);
            }, 0);
            this.inProgressShow = false;
          });
        } else {
          this.inProgressShow = false
        }
        return
      }

      let isPendingOtherProcess = false;
      do {
        let currentItem = null
        if (this.processDateValueChangedList.length > 0) {
          currentItem = this.processDateValueChangedList.shift()
        } else {
          currentItem = this.processValueChangedList.shift()
        }

        
        // const colId = currentItem.colId
        const property = currentItem.property
        //const oldValue = currentItem.oldValue
        const newValue = currentItem.newValue
        const type = currentItem.data.type;
        // const rowData = currentItem.data
        let entityId = currentItem.entityId

        if (currentItem.property === 'displayName' || currentItem.property === 'description' || currentItem.property === 'append_after') {
          if (currentItem.property === 'displayName') {
            currentItem.data.profileData.displayName = newValue;
          }
          else if (currentItem.property === 'description') {
            currentItem.data.profileData.description = newValue;
          } else if (currentItem.property === 'append_after') {
            currentItem.data.profileData.append_after = newValue;
          }
          
          const categoryKey = 'customField';
          const folderKey = 'system';
          const userId = '00000000-0000-0000-0000-000000000001';
          profileService.update([currentItem.data.profileData], 'customField', 'system', '00000000-0000-0000-0000-000000000001');
          
          /*let result = { value: [{
              method: 'PUT',
              invoke: `/api/profile/${categoryKey}/${folderKey}/${userId}/update`,
              body: [currentItem.data.profileData],
              vars: [],
              note: `field_update_profile_${currentItem.data.profile}`
            }],
            status: 'SUCCESS'
          };

          if (result.status == operationStatus.SUCCESS) {
            if (result.isDataProp) {
              //This flow is meant for handling custom data format for entity's property (not link/edge)
              const entityObj = { model: this.entity, name: entityId }
              const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority']
              const newVal = result.value
              if (typeof newVal == 'string' && newVal.trim().length == 0 && blankToNullList.includes(property)) {
                entityObj[property] = null
              } else {
                entityObj[property] = newVal
              }
              this.pendingProcessRequestList.push({
                method: 'PUT',
                invoke: `/api/system/schema?type=field&object=${this.entity}.${entityId}&opts=allowModify,allowRename,allowCleanup`,
                body: entityObj,
                vars: [],
                note: `${this.entity}_update__${property}`
              })
            } else if (result.value.length > 0) {
              //This flow is meant for handling custom data format for entity's property which is a link/edge
              this.pendingProcessRequestList.push(...result.value)
            }
          } else if (result.status == operationStatus.ABORT) {
            const prop = result.property != null? result.property : property
            const val = Object.hasOwn(result, 'value')? result.value : oldValue
            
            const rowNode = api.getRowNode(entityId)
            if (rowNode != null) {
              const data = rowNode.data
              data[prop] = val
              rowNode.setData(data)
            }
          }*/
          
        } else { // update entity
          const entityObj = { model: this.entity, name: entityId }
          const blankToNullList = ['currencyCode', 'complexity', 'durationAUM', 'priority']
          const numeric = ['min', 'max'];
          if (numeric.includes(property) ||
              ((type === 'Long' || type === 'Integer' || type === 'Byte') &&
               property === 'def')) {
            if (property === 'max' && newValue === null) {
              entityObj[property] = 32503680000000;
            }
            else if (property === 'min' && newValue === null) {
              entityObj[property] = 0;
            }
            else {
              entityObj[property] = parseInt(newValue);
            }
          }
          else if (property === 'def' && 
                   type === 'Float') {
            entityObj[property] = parseFloat(newValue);       
          }
          else if (typeof newValue == 'string' && newValue.trim().length == 0 && blankToNullList.includes(property)) {
            entityObj[property] = null
          } else {
            entityObj[property] = newValue
          }
          
          this.pendingProcessRequestList.push({
            method: 'PUT',
            invoke: `/api/system/schema?type=field&object=${this.entity}.${entityId}&opts=allowModify,allowRename,allowCleanup`,
            body: entityObj,
            vars: [],
            note: `${this.entity}_update__${property}__${type}`
          })
        }

      } while(this.processValueChangedList.length > 0);

      if(!isPendingOtherProcess && this.processValueChangedList.length == 0 && this.processDateValueChangedList.length == 0) {
        this.inProgressShow = false;
        //Last, call itself again to begin next iteration
        this.processValueChanged(api);
      }
    }
    , resetFocus(api, cell) {
      api.clearRangeSelection()
      if (cell != null && cell.rowIndex != null && cell.colId != null) {
        api.setFocusedCell(cell.rowIndex, cell.colId, null)
        api.addCellRange({
          rowStartIndex: cell.rowIndex
          , rowEndIndex: cell.rowIndex
          , columnStart: cell.colId
          , columnEnd: cell.colId
        })
      }
    }
    , keyDownHandler(e) {
      if (!this.show) {
        return
      }

      if (!e.target.classList.contains('ag-cell') && e.target.tagName != 'BODY' || e.target.classList.contains('modal-open')) {
        return
      }

      if (e.key == 'Delete' && e.target.closest('.generic-selector') == null 
          && this.gridApi.getCellRanges() != null && this.gridApi.getCellRanges().length > 0) {
        //Construct the necessary payload value and call the cellKeyDown method 
        this.onCellKeyDown({ api: this.gridApi, event: { keyCode: e.keyCode, key: e.key } })
      } else if (e.keyCode == 36 || e.keyCode == 35) {//Home & End Key
        const api = this.gridApi
        if (api == null) {
          return
        }
        e.stopPropagation()
        e.preventDefault()
        if (e.shiftKey) {
          return
        }
        const rowCount = api.getDisplayedRowCount()
        if (rowCount == 0) {
          return
        }

        const firstRowIndex = 0
        const lastRowIndex = rowCount - 1
        const columns = this.gridApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
        const startColumn = columns[0]
        const lastColumn = columns[columns.length - 1]

        const rowIndex = e.keyCode == 36? firstRowIndex : lastRowIndex
        const colId = e.keyCode == 36? startColumn : lastColumn
        const vPosition = e.keyCode == 36? 'top' : 'bottom'

        if (e.ctrlKey) {
          this.navigateCellTo(api, rowIndex, colId, vPosition)
        } else {
          const focusedCell = api.getFocusedCell()
          if (focusedCell != null) {
            this.navigateCellTo(api, focusedCell.rowIndex, colId, null)
          }
        }
        return
      } else if (e.keyCode == 37) { //ArrowLeft Key
        if (e.ctrlKey != true) {
          return 
        }
        e.stopPropagation()
        e.preventDefault()
        const api = this.gridApi
        const focusedCell = api.getFocusedCell()
        if (focusedCell != null) {
          const columns = this.gridApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
          this.navigateCellTo(api, focusedCell.rowIndex, columns[0], null)
        }
      } else if (e.ctrlKey && e.keyCode == 65) {
        e.stopPropagation()
        e.preventDefault()
        const api = this.gridApi
        const totalCount = api.getDisplayedRowCount()
        if (totalCount == 0) {
          return
        }
        const columns = this.gridApi.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)

        api.clearRangeSelection()
        api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: totalCount - 1,
          columns
        })
        //Set a focus cell if there is none
        const focusCell = api.getFocusedCell()
        if (focusCell != null) {
          return
        }
        api.setFocusedCell(0, columns[0], null)
      } else if (e.ctrlKey && e.keyCode == 68) {//'D'
        e.stopPropagation()
        e.preventDefault()
      }

    }
    , navigateCellTo(api, pRowIndex, pColId, vPosition=null) {
      setTimeout(() => {
        let rowIndex = pRowIndex
        const colId = pColId
        api.clearRangeSelection()
        api.setFocusedCell(rowIndex, colId, null)
        api.addCellRange({
          rowStartIndex: rowIndex
          , rowEndIndex: rowIndex
          , columns: [colId]
        })
        api.ensureIndexVisible(rowIndex, vPosition)
        api.ensureColumnVisible(colId, 'auto')
      })
    }
    , onCellKeyDown(params) {
      if (params.event.keyCode == 46) {
        const cellRanges = params.api.getCellRanges()
        //Prepare cell information
        const targetColEntities = []
        const processedCells = [] //Used to elimate duplicate records.
        for (const cRange of cellRanges) {
          const startRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
          const lastRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex 
          
          const columns = cRange.columns
          for (let idx = startRowIdx; idx <= lastRowIdx; idx++) {
            const rowNode = params.api.getDisplayedRowAtIndex(idx)
            
            for (const column of columns) {
              const colId = column.colId
              const rowId = rowNode.data.id
              const found = processedCells.find(i => rowId == i.rowId && ('name' == i.colId || colId == i.colId))
              if (found != null) {
                continue //Duplicated cell is found. Process to next iteration.
              }
              processedCells.push({ rowId, colId })

              //Handle 'targetColumn' cell
              //Brief: Delete targetColumn cell means remove the whole row
              if ('name' == colId) {
                targetColEntities.push({
                   uuId: rowNode.id
                  , name: rowNode.data.name
                  , parent: rowNode.data.pUuId
                  , parentName: rowNode.data.pName
                  , colId
                })
                continue
              }

              if (rowNode.data[colId] == null) {
                continue //Skip when the property value is null.
              }

              //Handle non targetColumn cell
              //Skip when the cell is not editable
              let isEditable
              if (typeof this.defaultColDef.editable === 'function') {
                isEditable = this.defaultColDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }})
              } else if (typeof this.defaultColDef.editable === 'boolean') {
                isEditable = this.defaultColDef.editable
              }
              const colDef = this.columnDefs.find(i => i.colId == colId || i.field == colId)
              if (typeof colDef.editable === 'function') {
                isEditable = colDef.editable({ data: { uuId: rowId, taskType: rowNode.data.taskType }})
              } else if (typeof colDef.editable === 'boolean') {
                isEditable = colDef.editable
              }
              if (!isEditable) {
                continue
              }
              
              this.pendingDeleteCells.push({
                colId
                , data: objectClone(rowNode.data)
                , property: colId
                , entityId: rowId
                , parenId: rowNode.data.pUuId
                , taskName: rowNode.data.name
                // , taskType: rowNode.data.taskType
                // , isTaskCol: false
                , value: rowNode.data[colId]
              })
            }
          }
        }
        
        if (targetColEntities.length > 0) {
          //Prepare data for taskcol delete confirmation dialog
          this.ecConfirmDeleteEntities = targetColEntities
          this.prepareTargetColConfirmDeleteDialog()
        } else if (this.pendingDeleteCells.length > 0) {
          this.deleteCell()
        }
      } else if ((params.event.keyCode == 13 || params.event.keyCode == 68) && params.event.ctrlKey) {
        const api = params.api
        if (api == null) {
          return
        }

        const focusedCell = api.getFocusedCell()
        if (focusedCell == null) {
          return
        }

        const cellRanges = api.getCellRanges()
        if (cellRanges.length == 0 || (cellRanges.length > 1 && params.event.keyCode == 68)) { //Ctrl+D supports only single range
          return
        }

        const fRange = cellRanges.find(i => {
          if (i.startRow != null && i.endRow != null && i.columns.find(j => j.colId == focusedCell.column.colId) != null) {
            return i.startRow.rowIndex <= focusedCell.rowIndex && focusedCell.rowIndex <= i.endRow.rowIndex
          }
          return false
        })
        if (fRange == null) {
          return
        }
        
        //Stop operation when range is a single cell selection because nothing to copy and fill.
        if (fRange.startRow.rowIndex == fRange.endRow.rowIndex && fRange.columns.length == 1) {
          return
        }

        const rowIndex = fRange.startRow.rowIndex < fRange.endRow.rowIndex? fRange.startRow.rowIndex : fRange.endRow.rowIndex
        this.ctrlEnterFillCell(api, rowIndex, fRange.columns[0].colId)
        return
      } else if (params.event.keyCode == 13) {
        //Navigate to next cell when user press enter on the read-only cell.
        let editable = false
        const api = params.api
        if (api == null) {
          return
        }
        const fRowIndex = params.rowIndex
        const column = params.column
        if (typeof column.colDef.editable == 'boolean') {
          editable = column.colDef.editable
        } else if (typeof column.colDef.editable == 'function') {
          const data = params.data
          editable = column.colDef.editable({ data })
        }
        if (!editable) {
          let rowIndex
          if (params.event.shiftKey) {
            rowIndex = fRowIndex - 1
            if (rowIndex < 0) {
              rowIndex = 0
            }
          } else {
            rowIndex = fRowIndex + 1
            const lastRowIndex = api.getDisplayedRowCount() - 1
            if (rowIndex > lastRowIndex) {
              rowIndex = lastRowIndex
            }
          }
          //Navigate to next cell below when user press enter on ready only cell.
          if (api.getDisplayedRowAtIndex(rowIndex) != null) {
            this.navigateCellTo(api, rowIndex, column.colId, null)
          }
        }
      }
    }
    , fillOperation(params) {
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.rowNode
        , data: params.rowNode.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , context: params.context
      }
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return params.currentCellValue
      }

      //Skip when the target cell is blacklisted
      const blackListed = ['totalActualDuration']
      if (blackListed.includes(params.column.colId)) {
        return params.currentCellValue
      }

      //Used to signal batch operation in onCellValueChanged event.
      this.triggeredByFillOperation = true

      //Find the source Column, source rowNode based on the available details in params object.
      const tgtRowNode = params.rowNode
      const targetColId = params.column.colId
      let srcRowNode = null 
      let sourceColId = null
      
      if (params.direction == 'left' || params.direction == 'right') {
        srcRowNode = tgtRowNode //Default: Same row as target.
        const columns = params.api.getColumnState().filter(i => i.hide != true).map(i => i.colId)
        const targetColIdx = columns.findIndex(i => i == targetColId)
        let sourceColIdx = targetColIdx - params.currentIndex - 1
        if (params.direction == 'left') {
          sourceColIdx = targetColIdx + params.currentIndex + 1
        }
        sourceColId = columns[sourceColIdx]
      } else {// direction: up or down
        let srcRowIndex = tgtRowNode.rowIndex - params.currentIndex - 1
        if (params.direction == 'up') {
          srcRowIndex = tgtRowNode.rowIndex + params.currentIndex + 1
        }
        srcRowNode = params.api.getDisplayedRowAtIndex(srcRowIndex)
        sourceColId = targetColId //Default: Same column as source.
      }

      let srcProperty = sourceColId
      let tgtProperty = targetColId
      //Check if the source and target's property value type are compatible
      if (srcProperty != tgtProperty) {
        if (sourceColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          srcProperty = 'name'
        }
        if (targetColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
          tgtProperty = 'name'
        }
        const compatibleResult = this.isPropertyCompatible(srcProperty, tgtProperty)
        if (!compatibleResult.status) {
          return tgtRowNode.data[compatibleResult.colId != null? compatibleResult.colId : tgtProperty]
        }
      }

      let srcValue = srcRowNode.data[sourceColId == this.COLUMN_AGGRID_AUTOCOLUMN? 'name' : sourceColId]
      let tgtValue = objectClone(tgtRowNode.data[targetColId])

      //Skip when the target cell is a mandatory field and the new value is either blank or null
      if ((this.mandatoryFields.length > 0 && 
          this.mandatoryFields.includes(tgtProperty) && 
          (srcValue == null || typeof srcValue == 'string' && srcValue.trim().length == 0 ||
            Array.isArray(srcValue) && srcValue.length == 0)) ||
          srcRowNode.data.type !== tgtRowNode.data.type ||
          targetColId === 'name') {
        return tgtValue
      }

      const result = this.propertyCopyOperation(tgtProperty, srcValue, tgtRowNode.data)
      if (result.status == operationStatus.ABORT) {
        return tgtValue
      }
      return result.value
    }
    , processCellForClipboard(params) {
      const rowData = params.node.data
      let srcColId = params.column.colId
      if (srcColId == this.COLUMN_AGGRID_AUTOCOLUMN) {
        srcColId = 'name'
      }

      const srcRowId = rowData.id;
      const srcRowData = params.api.getRowNode(srcRowId).data
      
      const source = {
        colId: srcColId
        , type: srcRowData.type
        // , data: objectClone(srcRowData)
        , value: srcRowData[srcColId]
        , property: srcColId
        , entityId:   srcRowData.id
        // , parentId: srcRowData.pUuId
        // , taskName: srcRowData.name
        // , taskType: srcRowData.taskType
      }

      return this.cellCopyPrefix+JSON.stringify(source)
    }
    , processCellFromClipboard(params) {
      let rowData = params.node.data
      //Skip when the target cell is not editable or is rowSelector.
      const payload = { 
        node: params.node
        , data: params.node.data
        , column: params.column
        , colDef: params.column.colDef
        , api: params.api
        , context: params.context
      }
      
      if ((typeof params.column.colDef.editable === 'function' && !params.column.colDef.editable(payload)) || 
          !params.column.colDef.editable || params.column.colId == 'rowSelector') {
        return rowData[params.column.colId]
      }

      let colId = params.column.colId
      
      if (colId == this.COLUMN_AGGRID_AUTOCOLUMN) {
        colId = 'name'
      }
      
      //Skip when the target cell is blacklisted
      const blackListed = ['name']
      if (blackListed.includes(colId)) {
        return rowData[colId]
      }

      let source = params.value
      //Defensive code: return original value when the value is not from aggrid cell copy.
      if (typeof source !== 'string' || !source.startsWith(this.cellCopyPrefix)) {
        return rowData[colId]
      }

      //Restore value to JSON object.
      source = source.substring(this.cellCopyPrefix.length)
      source = JSON.parse(source)
      
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , value: rowData[colId]
        , property: colId
        , entityId:   rowData.uuId
        , parentId: rowData.pUuId
        , entityName: rowData.name
      }

      // if the row types are not the same and the property is min, max or def
      if (source.type !== rowData.type &&
          (source.property === 'min' ||
           source.property === 'max' ||
           source.property === 'def')) {
        return rowData[colId];  // return the original value     
      }
      
      if (source.property != target.property) {
        const compatibleResult = this.isPropertyCompatible(source.property, target.property)
        if (!compatibleResult.status) {
          return rowData[compatibleResult.colId != null? compatibleResult.colId : target.property]
        }
      }

      let srcValue = source.value

      //Skip when the target cell is a mandatory field and the new value is either blank or null
      if (this.mandatoryFields.length > 0 && 
          this.mandatoryFields.includes(target.property) && 
          (srcValue == null || typeof srcValue == 'string' && srcValue.trim().length == 0 ||
            Array.isArray(srcValue) && srcValue.length == 0)) {
        return target.value
      }

      const result = this.propertyCopyOperation(target.property, srcValue, target.data)
      if (result.status == operationStatus.ABORT) {
        return result.colId != null? 
          rowData[result.colId] : (result.value != null? result.value : target.value)
      }
      return result.value
    }
    , prepareTargetCellData(colId, rowData, { color=null }={}) {
      const target = {
        colId: colId
        , data: objectClone(rowData)
        , oldValue: rowData[colId]
        , property: colId
        , entityId:   rowData.uuId
        , parentId: rowData.pUuId
        , taskName: rowData.name
        , taskType: rowData.taskType
        , color: color
      }

      return target
    }
    , ctrlEnterFillCellPropCheck_note(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix)
      let tgtNotes = tgtValue
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = []
        }
        tgtNotes = tgtValue.single
      }
      if (srcValue.length == 0 && tgtNotes.length == 0) {
        // return rowData[colId] //No change when both lists are empty.
        return { action: 'continue' }
      }

      //Remove the top item from the tgtNotes when src is empty
      if (srcValue.length == 0) {
        tgtNotes.shift()
        return { action: 'add', newValue: tgtNotes }
      }

      const newNote = objectClone(srcValue[0])
      if (newNote.identifier == null || newNote.identifier.trim().length == 0) {
        delete newNote.identifier
      }
      delete newNote.uuId
      tgtNotes.unshift(newNote)
      return { action: 'add', newValue: tgtNotes }
    }
    , ctrlEnterFillCellPropCheck_staff(srcValue, tgtValue) {
      const isTgtTaskCol = tgtValue.colId.startsWith(this.taskColPrefix)
      let tgtStaffs = tgtValue
      if (isTgtTaskCol) {
        if (tgtValue.single == null) {
          tgtValue.single = []
        }
        tgtStaffs = tgtValue.single
      }
      if (srcValue.length == 0 && tgtStaffs.length == 0) {
        //No change when both lists are empty.
        return { action: 'continue' }
      }

      let newValue = []
      if (srcValue.length > 0) {
        const updatedStaffs = []
        for (const srcStaff of srcValue) {
          const found = tgtStaffs.find(i => i.uuId == srcStaff.uuId)
          if (found != null) {
            //Update tgtStaff with srcStaff's utilization. Keep the rest.
            found.utilization = srcStaff.utilization
            updatedStaffs.push(found)
          } else {
            //Clean up workEffort (duration, durationAUM) when add to new list.
            delete srcStaff.duration
            delete srcStaff.durationAUM
            updatedStaffs.push(srcStaff)
          }
        }
        newValue = updatedStaffs
      }
      return { action: 'add', newValue }
    }
    , ctrlEnterFillCell(api, rowIndex, srcColId) {
      const srcRowNode = api.getDisplayedRowAtIndex(rowIndex)
      if (srcRowNode == null) {
        return
      }
      
      //Prepare source (property) value
      const srcRowData = srcRowNode.data
      const isSrcTaskCol = srcColId.startsWith(this.taskColPrefix)

      const source = {
        colId: srcColId
        , value: srcRowData[srcColId]
        , property: srcColId
        , taskId:   srcRowData.uuId
      }

      if (isSrcTaskCol) {
        const valueObj = source.value
        source.value = valueObj.single 
        source.property = valueObj.property
        source.taskId = valueObj.uuId != null? valueObj.uuId : null
      }

      //Skip when source is taskCol and has no valid task
      if (isSrcTaskCol && source.taskId == null) {
        return
      }

      const pendingFilled = []
      const cellRanges = api.getCellRanges()
      for (const cRange of cellRanges) {
        const rowStartIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
        const rowEndIndex = cRange.startRow.rowIndex < cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex
        const columns = cRange.columns.map(i => { return { colId: i.colId, color: i.userProvidedColDef.color }})
        for (let i = rowStartIndex; i <= rowEndIndex; i++) {
          const curRowNode = api.getDisplayedRowAtIndex(i)
          if (curRowNode == null) {
            continue
          }

          const tgtRowData = curRowNode.data
          for (const col of columns) {
            if (col.colId === srcColId && rowIndex == i) {
              continue //Skip when current cell (rowIndex & colId) is source cell
            }
            const target = this.prepareTargetCellData(col.colId, tgtRowData, { color: col.color })

            if (source.property != target.property) {
              const compatibleResult = this.isPropertyCompatible(source.property, target.property)
              if (!compatibleResult.status) {
                continue
              }
            }

            let srcValue = source.value

            const result = this.propertyCopyOperation(target.property, srcValue, tgtRowData)
            if (result.status == operationStatus.ABORT) {
              continue
            }
            target.newValue = result.value
            pendingFilled.push(target)
          }
        }
      }

      if (pendingFilled.length > 0) {
        this.processValueChangedList.push(...pendingFilled)
        this.inProgressLabel = this.$t('task.progress.updating_tasks')
        this.processValueChanged(api)
      }
    }
    , onPasteStart(/** params */) {
      this.isPasteInProgress = true
    }
    , onPasteEnd(params) {
      this.isPasteInProgress = false
      this.consolidateChangedCellValues(this.processValueChangedList)
      if (this.processValueChangedList.length > 0) {
        this.inProgressLabel = this.$t('task.progress.paste_tasks')
        this.processValueChanged(params.api)  
      }
    }
    , consolidateChangedCellValues(valueChangedList) {
      if (valueChangedList == null || !Array.isArray(valueChangedList)) {
        return
      }
      
      // if (Object.hasOwn(this.entityService, 'consolidatePropertyGroupWhenPaste')) {
      //   const resultList = this.entityService.consolidatePropertyGroupWhenPaste(valueChangedList)
      //   valueChangedList.splice(0, valueChangedList.length, ...resultList)
      // }
    }
    , navigateToNextCell(params) {
      const previousCellPosition = params.previousCellPosition
      const nextCellPosition = params.nextCellPosition
      if (nextCellPosition == null) {
        return previousCellPosition
      }
      //Clear range selection when move focus from cell to header.
      if (nextCellPosition.rowIndex < 0 && previousCellPosition.rowIndex > -1) {
        params.api.clearRangeSelection()
      }
      // Stay in previousCell when next Cell belonged to rowSelector column.
      if (nextCellPosition.column.colId === 'rowSelector') {
        return previousCellPosition
      }
      return nextCellPosition
    }
    , tabToNextCell(params) {
      //Fix for the bug: Multiple tabToNextCell events are fired when tab in cell editing.
      const curColId = params.previousCellPosition.column.colId
      const columns = params.api.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector')
      let rowIndex = params.previousCellPosition.rowIndex
      let index = columns.findIndex(i => i.colId == curColId)
      let nextColIdx
      if (index == 0 && params.backwards) {
        rowIndex -= 1
        nextColIdx = columns.length - 1
      } else if (index == columns.length-1 && !params.backwards) {
        rowIndex += 1
        nextColIdx = 0
      } else if (params.backwards) {
        nextColIdx = index - 1
      } else {
        nextColIdx = index + 1
      }
      const column = columns[nextColIdx]

      if  (this.tabTimeoutId == null) {
        this.tabRowIndex = rowIndex
        this.tabColumn = column
        this.tabTimeoutId = setTimeout(() => {
          this.tabRowIndex = null
          this.tabColumn = null
          this.tabTimeoutId = null
        }, 0)
        setTimeout(() => {
          params.api.clearRangeSelection()
          params.api.setFocusedCell(rowIndex, column.colId)
          params.api.addCellRange({
            rowStartIndex: rowIndex
            , rowEndIndex: rowIndex
            , columns: [column.colId]
          })
          params.api.ensureIndexVisible(rowIndex, null)
          params.api.ensureColumnVisible(column.colId, 'auto')
        }, 0)
        return params.previousCellPosition
      }
      //Skip cell navigation. 
      //Pro: to break infinite loop
      //Cons: will cause cell focus appear in the top left html element (e.g. Company logo)
      //Hence, a really short timeout duration is used to minimise the appearance of the cell focus.
      return null
    }
    , cellFocused(event) {
      if (event.rowIndex != null && event.column != null) {
        this.lastFocusedCell = { rowIndex: event.rowIndex, colId: event.column.colId }  
      }
    }
    , async deleteCell() {
      //deleteCell() expects only non targetColumn cell.
      const pendingItems = []
      const cells = this.pendingDeleteCells.splice(0, this.pendingDeleteCells.length)
      for (const cell of cells) {
        // Skip deleting cell when the property of the cell is a mandatory property/field of entity.
        if (this.mandatoryFields.includes(cell.property)) {
          continue
        }
        cell.oldValue = cell.value
        if (cell.property == 'color') {
          cell.newValue = null
        } else {
          //Some models may update the property in data. Hence, it is important that cell.data is passed as reference to keep the change and will be used later.
          const result = this.propertyDeleteOperation(cell.property, cell.data)
          if (result.state == operationStatus.ABORT) {
            continue
          }
          
          cell.newValue = result.value
        }
        delete cell.value
        pendingItems.push(cell)
      }
      
      if (pendingItems.length > 0) {
        const data = []
        for (const item of pendingItems) {
          const rowData = item.data //use item.data for applyTransaction because some delete actions may update other property values in the data. (e.g totalActualDuration)
          rowData[item.colId] = item.newValue
          data.push(rowData)
        }
        this.gridApi.applyTransaction({ update: data })
        this.processValueChangedList.push(...pendingItems)
      }
      
      // this.inProgressLabel = this.$t(`entity_selector.${this.formattedEntity}_delete_progress`)
      this.processValueChanged(this.gridApi, { customProgressLabel: this.$t(`entity_selector.${this.formattedEntity}_delete_progress`) })
    }
    , async deleteAll() {
      if (this.entityCol.applyAll == true) {
        while (this.ecConfirmDeleteEntities.length > 0) {
          const entity = this.ecConfirmDeleteEntities.shift()
          this.entityCol.entityId = entity.id
          this.entityCol.entityName = entity.name
          this.entityCol.applyAll = false
          this.entityCol.colId = entity.colId
              
          await sandboxService.remove(this.entityCol.entityId);
          if (parseInt(this.entityCol.entityId) === this.$store.state.sandbox.value) {
            EventBus.$emit('epoch-clear');
          }
        }
      }
    }
    , async ecConfirmDeleteOk() {
      const self = this;
      
      this.inProgressShow = true
      this.inProgressLabel = this.$t('sandbox.deleting')
      
      await sandboxService.remove(this.entityCol.entityId);
      if (parseInt(this.entityCol.entityId) === this.$store.state.sandbox.value) {
        EventBus.$emit('epoch-clear');
      }
      await this.deleteAll();
      this.reloadData()
    
      this.inProgressShow = false
      
      setTimeout(() => {
        this.prepareTargetColConfirmDeleteDialog()
      }, 300)
    }
    , prepareTargetColConfirmDeleteDialog() {
      if (this.ecConfirmDeleteEntities == null || this.ecConfirmDeleteEntities.length == 0) {
        this.deleteCell()
        return
      }
      
      const entity = this.ecConfirmDeleteEntities.shift()
      this.entityCol.entityId = entity.id
      this.entityCol.entityName = entity.name
      this.entityCol.applyAll = false
      this.entityCol.colId = entity.colId
      this.inProgressShow = false
      this.ecConfirmDeleteShow = true
    }
    , ecConfirmDeleteCancel() {
      if (this.pendingProcessRequestList.length > 0) {
        this.deleteCell()
      }
    }
    , propertyDeleteOperation(property, data) {
      //Custom handling logic
      let handler = this.getPropertyDeleteHandler(this);
      
      if (handler[property] != null) {
        const val = handler[property]
        if (typeof val === 'function') {
          const result = val(data)
          if (result != null && operationStatusKeys.includes(result.status)) {
            return { found: true, value: result.value, status: result.status }  //found prop is used for debug
          }
        } else {
          return { found: true, value: val, status: operationStatus.SUCCESS } //found prop is used for debug
        }
      }

      //Default handling logic
      let value = data[property]
      if (typeof value == 'string') {
        value = ''
      } else if (typeof value == 'number') {
        value = 0
      } else if (typeof value == 'object') {
        value = { uuId: null }
      } else if (Array.isArray(value)) {
        value = []
      }
      return { found: false, value, status: operationStatus.SUCCESS }
    }
    , propertyCopyHandler() {
      let maxNameLength = 200
      let maxIdentifierLength = 200
      if (this.modelInfo != null) {
        let val = this.modelInfo.filter(info => info.field === 'name')
        if (val.length > 0) {
          maxNameLength = val[0].max
        }
        val = this.modelInfo.filter(info => info.field === 'identifier')
        if (val.length > 0) {
          maxIdentifierLength = val[0].max
        }
      } 

      //Expected format when return value is a function:
      //{ value, status } or
      //{ value, status, colId } when status is ABORT
      //Possible status: 'SUCCESS' | 'ABORT'
      //colId is optional but is useful for specifying a different colId as reset value.
      return {
        color: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue != null && srcValue.trim().length == 0) {
            value = null
          }
          return { value, status: 'SUCCESS' }
        }
        , name: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue != null && srcValue.length > maxNameLength) {
            value = srcValue.substring(0, maxNameLength)
          }
          return { value, status: 'SUCCESS' }
        }
        , identifier: (srcValue /**, tgtData*/) => {
          let value = srcValue
          if (srcValue != null && value.length > maxIdentifierLength) {
            value = srcValue.substring(0, maxIdentifierLength)
          }
          return { value, status: 'SUCCESS' }
        }
      }
    }
    , propertyCopyOperation(property, srcValue, tgtData) {
      const handler = this.propertyCopyHandler()
      
      //When no handler for specific property, just copy value
      if (!Object.hasOwn(handler, property)) {
        return { value: srcValue, status: operationStatus.SUCCESS }
      }

      const val = handler[property]
      if (typeof val === 'function') {
        const result = val(srcValue, tgtData)
        if (result != null && operationStatusKeys.includes(result.status)) {
          return result
        }
      }
      return { value: val, status: operationStatus.SUCCESS }
    }
    , isEditable(params) {
      return params.node.data.id !== "0";
    }
    , httpAjaxError(e) {
      // console.error(e) //eslint-disable-line no-console
      const response = e.response;
      let alertMsg = this.$t('error.internal_server')
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action')
      }
      else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        const clue = feedback.clue.trim().toLowerCase();
        const args = feedback.args;
        const note = feedback.note;
        if (clue === 'not_unique_key') {
          alertMsg = this.$t('fields.error.not_unique_key');
        }
        else if (clue === 'number_limit_exceeded' ||
                 clue === 'string_limit_exceeded') {
          args[0] = this.$t(`fields.${args[0]}`);
          if (note.endsWith('Date')) {
            args[1] = moment(args[1]).format('YYYY-MM-DD');
          }
          alertMsg = this.$t('fields.error.number_limit_exceeded', args);
        }
        else if (clue === 'number_limit_under' ||
                 clue === 'string_limit_under') {
          args[0] = this.$t(`fields.${args[0]}`);
          if (note.endsWith('Date')) {
            args[1] = moment(args[1]).format('YYYY-MM-DD');
          }
          alertMsg = this.$t('fields.error.number_limit_under', args);
        }
        else if (clue === 'number_limit_over' ||
                 clue === 'string_limit_over') {
          args[0] = this.$t(`fields.${args[0]}`);
          if (note.endsWith('Date')) {
            args[1] = moment(args[1]).format('YYYY-MM-DD');
          }
          alertMsg = this.$t('fields.error.number_limit_over', args);
        }
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
      this.scrollToTop();
    }
    , async reloadData(callbackFunc=null) {
      
      this.noRowsMessage = null

      const list = await sandboxService.list().then((response) => {
        return response.data[response.data.jobCase].map(r => { return { uuId: r.identifier, name: r.name, created: r.created, createdAt: r.createdAt, owner: r.owner, viewBy: r.viewBy, editBy: r.editBy }});
      }).catch(e => {
        this.entitySelection.splice(0, this.entitySelection.length)
        return [];
      });
      
      if (list.length > 0) {
        await userService.get(list.map(l => { return { uuId: l.owner }})).then( response => {
          for (const l of list) {
            l.ownerName = response.data[response.data.jobCase].find(j => j.uuId === l.owner).name;
          }
        }).catch(e => { 
          console.log(e); // eslint-disable-line
        });
      }
                        
      this.rowData = list;
      
      if(callbackFunc && typeof callbackFunc === 'function') {
        callbackFunc()
      }
      
      if (this.gridApi) {
        this.gridApi.setGridOption('rowData', this.rowData)
      }
    }
    , isPropertyCompatible(src, tgt) {
      if (src === tgt) {
        return { status: true }
      }
    
      const keys = Object.keys(this.dataGroup)
      for(const key of keys) {
        if (this.dataGroup[key].includes(src) && this.dataGroup[key].includes(tgt)) {
          return { status: true }
        }  
      }
      return { status: false, colId: tgt }
    }
    , getPropertyDeleteHandler() {
      return {

      }
    }
    , prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage;  
      }
      return this.$t('sandbox.grid.no_data');
    }
    , showNoRowsOverlay(msg=null) {
      this.noRowsMessage = msg
      if (this.gridApi != null) {
        this.gridApi.hideOverlay()
        setTimeout(() => {
          this.gridApi.showNoRowsOverlay()
        })
      }
    }
    , onCellEditingStarted(/** event **/) {
      this.isCellEditing = true;
    }
    , onCellEditingStopped(/** event **/) {
      this.isCellEditing = false;
    }
  }
}


</script>

<style lang="scss">
.spreadsheet .ag-row-selected::before,
.spreadsheet .ag-row-selected.ag-row-hover::before {
  background-color: transparent;
}

.spreadsheet .ag-cell-range-selected.ag-cell-focus, .spreadsheet .ag-body-viewport.ag-has-focus .ag-cell-range-single-cell:not(.ag-cell-inline-editing) {
  background-color: var(--ag-range-selection-background-color);
  border: 2px solid  !important;
  border-color: var(--ag-range-selection-border-color) !important; 
  border-style: var(--ag-range-selection-border-style) !important;
}

.changed-dot {
  content: '';
  display: inline-block;
  height: 0.6em;
  width: 0.6em;
  margin-left: 4px;
  background: red;
  border-radius: 50%;
}
</style>